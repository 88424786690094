import React, {Component} from "react";

import ColorPickerSearch from "./ColorPickerSearch";

export default class ColorPicker extends Component {

    onChange = (e) => this.selectColor(e.target.value);

    selectColor = (text) => {
        this.props.setRowValue([this.props.name], text);
    };

    render() {
        const id = `${this.props.id}-${this.props.suffix}`;
        const id2 = `${this.props.id}-modal-${this.props.suffix}`;
        const id3 = `${this.props.id}-modal-label-${this.props.suffix}`;
        if (this.props.dropdownList) {
            return (
                <div>
                    <label htmlFor={id}>{this.props.label}</label>
                    <div className="input-group mb-0">
                        <input type="text" id={id} placeholder="RAL / NCS"
                               className="form-control form-control-sm"
                               value={this.props.value}
                               disabled={this.props.disabled}
                               onChange={this.onChange}/>
                        <span className="input-group-append">
                            <button type="button"
                                    className="btn btn-sm btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    disabled={this.props.disabled}
                                    style={{backgroundColor: this.props.web}}>
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-right" role="menu">
                                <li type="button" className="dropdown-item" style={{background:"#A5A8A6", color:"#ffffff"}} onClick={() => this.selectColor("Серый анод")}>Серый анод</li>
                                <li type="button" className="dropdown-item" style={{background:"#211F20", color:"#ffffff"}} onClick={() => this.selectColor("Венге")}>Венге</li>
                                <li type="button"
                                        className="dropdown-item"
                                        data-toggle="modal"
                                        data-target={`#${id2}`}>RAL / NCS
                                    <span className="dropdown-toggle">&nbsp;</span><span className="sr-only">Toggle Dropdown</span>
                                </li>
                            </ul>
                            <div className="modal fade" id={id2} tabIndex="-1" role="dialog"
                                 aria-labelledby={id3} aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id={id3}>Выбор цвета</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <ColorPickerSearch suffix={this.props.suffix} colors={this.props.colors} value={this.props.value} selectColor={this.selectColor}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <label htmlFor={id}>{this.props.label}</label>
                    <div className="input-group mb-0">
                        <input type="text" id={id} placeholder="RAL / NCS"
                               className="form-control form-control-sm"
                               value={this.props.value}
                               disabled={this.props.disabled}
                               onChange={this.onChange}/>
                        <span className="input-group-append">
                        <button type="button"
                                className="btn btn-sm btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                                data-toggle="modal"
                                data-target={`#${id2}`}
                                disabled={this.props.disabled}
                                style={{backgroundColor: this.props.web}}>
                            <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        <div className="modal fade" id={id2} tabIndex="-1" role="dialog"
                             aria-labelledby={id3} aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={id3}>Выбор цвета</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ColorPickerSearch suffix={this.props.suffix} colors={this.props.colors} value={this.props.value} selectColor={this.selectColor}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                    </div>
                </div>
            );
        }
    }
};
