import React from "react";
import {Link} from "react-router-dom";

import Logo from "../img/logo.png"

const Header = () => {
    return (
        <header className="bg-dark d-flex justify-content-between">
            <Link to="/"><img src={Logo} alt="logo" className="my-2 ml-3" style={{"height": "40px"}}/></Link>
            <a href="https://allumit.org/" role="button" className="btn btn-warning btn-primary mx-2 my-2 text-center" style={{"height": "40px"}}>Вернуться на сайт</a>
        </header>
    );
};

export default Header;
