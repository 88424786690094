import React, {Component} from 'react';

import colors from "../colors.json"
import DoorsTableRow from "./DoorsTableRow";
import AdditionalExpenses from "./AdditionalExpenses";
import Print from "./Print";

export default class DoorsCalculator extends Component {
    state = {
        rows: [],
        currency: "",
        total: "",
        additionalExpenses: {
            totalDiscount: "",
            installation: "",
            delivery: "",
            deliveryCustomPrice: 0,
            lifting: "",
            liftingTotal: "",
            floor: 1,
            total: "",
            notes: ""
        },
        data: {},
        colors: colors,
        loaded: false,
        loadError: false,
        error: false,
        errorMessage: "",
    };
    nextRowId = 1;
    bankError = "На данный момент нет доступа к серверу \"Приват Банк\". Повторите попытку позже!";
    currenciesError = "Ошибка получения данных курсов валют с сервера \"Приват Банк\"!";
    fillError = "Заполните все обязательные поля!";
    openingHeightError = "Высота проема меньше минимально допустимой";

    onAddRow = () => {
        this.setState(state => {
            const row = {id: this.nextRowId++, model: this.getFirstValue(state.data.models), profileColor: "Серый анод", profileColorWeb: this.getColorWeb("Серый анод"), doorType: this.getFirstValue(state.data.doorTypes), openingType: "Кирпич",
                openingHeight: "", openingWidth: "", clearHeight: "", clearWidth: "", maxHeightError: false, maxWidthError: false, minHeightError: false, minWidthError: false,
                opening: "Тип открывание №1", openingUpperTitle: "", openingLowerTitle: "",
                materialInner: this.getFirstValue(state.data.materials.filter(m => !m.onlyMaterial)), materialInnerColor: "", materialInnerColorWeb: "", materialOuter: this.getFirstValue(state.data.materials), materialOuterColor: "", materialOuterColorWeb: "",
                handle: this.getFirstValue(state.data.handles), handleColor: "", handlePrice: 0,
                lock: this.getFirstValue(state.data.locks), locking: this.getFirstValue(state.data.lockings), threshold: this.getFirstValue(state.data.thresholds),
                loopType: this.getFirstValue(state.data.loopTypes), loopsCount: "", loopsTotal: 0,
                doorsCount: 1, unitPrice: 0, dealerMargin: "", sum: 0, discount: ""};
            row.handleModel = row.handle.models ? this.getFirstValue(row.handle.models) : null;
            row.unitPrice = this.calculateUnitPrice(row);
            row.sum = this.calculateSum(row);
            this.setMaterialStuffIfNeed(row, this.state);
            return {rows: [...this.state.rows, row], total: "", additionalExpenses: {...this.state.additionalExpenses, installation: "", liftingTotal: "", total: ""}};
        });
    };

    onRemoveRow = (rowId) => {
        this.setState(state => {
            const id = state.rows.findIndex(row => row.id === rowId);
            return {rows: [...state.rows.slice(0, id), ...state.rows.slice(id + 1)], total: "", additionalExpenses: {...state.additionalExpenses, installation: "", liftingTotal: "", total: ""}};
        });
    };

    setRowValue = (rowId, prop, value) => {
        // console.log("setRowValue", rowId, prop, value);
        this.setState((state) => {
            const id = this.state.rows.findIndex(row => row.id === rowId);
            const row = this.state.rows[id];
            let item = {...row, [prop]: value};

            if (prop[0] === 'doorType') {
                item.doorType = state.data.doorTypes[state.data.doorTypes.findIndex(doorType => doorType.name === item.doorType)];
            } else if (prop[0] === 'discount') {
                item.discount = item.discount ? Math.min(+item.discount, 100) : item.discount;
            } else if (prop[0] === 'dealerMargin') {
                item.dealerMargin = item.dealerMargin ? Math.min(+item.dealerMargin, 100) : item.dealerMargin;
            } else if (prop[0] === 'materialInner') {
                item.materialInner = state.data.materials[state.data.materials.findIndex(material => material.name === item.materialInner)];
            } else if (prop[0] === 'materialOuter') {
                item.materialOuter = state.data.materials[state.data.materials.findIndex(material => material.name === item.materialOuter)];
                this.setMaterialStuffIfNeed(item, state);
            } else if (prop[0] === 'lock') {
                item.lock = state.data.locks[state.data.locks.findIndex(lock => lock.name === item.lock)];
            } else if (prop[0] === 'locking') {
                item.locking = state.data.lockings[state.data.lockings.findIndex(locking => locking.name === item.locking)];
            } else if (prop[0] === 'threshold') {
                item.threshold = state.data.thresholds[state.data.thresholds.findIndex(threshold => threshold.name === item.threshold)];
            } else if (prop[0] === 'doorsCount' && item.doorsCount) {
                item.doorsCount = +item.doorsCount;
            } else if (prop[0] === 'handle') {
                item.handle = state.data.handles[state.data.handles.findIndex(handle => handle.name === item.handle)];
                item.handleModel = item.handle && item.handle.models && item.handle.models.length > 0 ? item.handle.models[0] : null;
                item.handleColor = item.handleModel && item.handleModel.types && item.handleModel.types.length > 0 ? item.handleModel.types[0] : null;
            } else if (prop[0] === 'handleModel') {
                const modelIndex = item.handle.models.findIndex(model => model.name === item.handleModel);
                item.handleModel = item.handle.models[modelIndex];
                item.handleColor = item.handleModel && item.handleModel.types && item.handleModel.types.length > 0 ? item.handleModel.types[0] : null;
            } else if (prop[0] === 'handleColor') {
                const colorIndex = item.handleModel.types.findIndex(color => color.name === item.handleColor);
                item.handleColor = item.handleModel.types[colorIndex];
            } else if (prop[0] === 'handlePrice' && item.handlePrice) {
                item.handlePrice = +item.handlePrice;
            } else if (prop[0] === 'profileColor') {
                item.profileColorWeb = item.profileColor ? this.getColorWeb(item.profileColor) : "";
            } else if (prop[0] === 'materialInnerColor') {
                item.materialInnerColorWeb = item.materialInnerColor ? this.getColorWeb(item.materialInnerColor) : "";
            } else if (prop[0] === 'materialOuterColor') {
                item.materialOuterColorWeb = item.materialOuterColor ? this.getColorWeb(item.materialOuterColor) : "";
            } else {
                if (prop[0] === 'model') {
                    item.model = state.data.models[state.data.models.findIndex(model => model.name === item.model)];
                } else if (prop[0] === 'loopType') {
                    item.loopType = state.data.loopTypes[state.data.loopTypes.findIndex(loopType => loopType.name === item.loopType)];
                }
                if (item.openingWidth !== "") {
                    item.openingWidth = +item.openingWidth;
                    item.minWidthError = item.openingWidth && item.openingWidth < item.model.minWidth;
                    item.maxWidthError = item.openingWidth && item.openingWidth > item.model.maxWidth;

                    item.openingWidth = item.openingWidth ? Math.min(item.openingWidth, item.model.maxWidth) : item.openingWidth;
                    item.clearWidth = item.openingWidth && item.openingWidth > state.data.clearWidth ? item.openingWidth - state.data.clearWidth : item.clearWidth;
                }
                if (item.openingHeight !== "") {
                    item.openingHeight = +item.openingHeight;
                    item.minHeightError = item.openingHeight && item.openingHeight < item.model.minHeight;
                    item.maxHeightError = item.openingHeight && item.openingHeight > item.model.maxHeight;

                    item.openingHeight = item.openingHeight ? Math.min(item.openingHeight, item.model.maxHeight) : item.openingHeight;
                    item.clearHeight = item.openingHeight && item.openingHeight > state.data.clearHeight ? item.openingHeight - state.data.clearHeight : item.clearHeight;

                    if (prop[0] === 'openingHeight' || prop[0] === 'loopType') {
                        if (item.loopType.loopCloser) {
                            item.loopsCount = item.openingHeight ? (item.openingHeight < 2400 ? 2 : (item.loopType.battista ? 3 : 4)) : item.loopsCount;
                        } else {
                            item.loopsCount = item.openingHeight ? (item.openingHeight < 2200 ? 2 : item.openingHeight < 2400 ? 3 : 4) : item.loopsCount;
                        }
                    }
                    if (item.loopsCount !== "") {
                        item.loopsCount = +item.loopsCount;
                        item.loopsTotal = item.loopType.price * item.loopsCount + (item.loopType.loopCloser ? (item.loopType.loopCloserPrice ? item.loopType.loopCloserPrice : item.loopType.price) * item.loopType.loopCloser : 0);
                    }
                }
            }
            item.unitPrice = this.calculateUnitPrice(item);
            item.sum = this.calculateSum(item);
            return {rows: [...this.state.rows.slice(0, id), item, ...this.state.rows.slice(id + 1)], total: "", additionalExpenses: {...this.state.additionalExpenses, installation: "", liftingTotal: "", total: ""}};
        });
    };

    setMaterialStuffIfNeed = (item, state) => {
        if (item.materialOuter.profileColor) {
            item.profileColor = item.materialOuter.profileColor;
            item.profileColorWeb = item.profileColor ? this.getColorWeb(item.profileColor) : "";
        }
        if (item.materialOuter.doorType) {
            item.doorType = state.data.doorTypes[state.data.doorTypes.findIndex(doorType => doorType.name === item.materialOuter.doorType)];
            this.assertValue(item.doorType, "Неизвестный тип двери: " + item.materialOuter.doorType);
            item.doorType = item.doorType || this.getFirstValue(state.data.doorTypes);
        }
        if (item.materialOuter.openingHeight) {
            item.openingHeight = +item.materialOuter.openingHeight;
            item.clearHeight = item.openingHeight - state.data.clearHeight;
        }
        if (item.materialOuter.lock) {
            item.lock = state.data.locks[state.data.locks.findIndex(lock => lock.name === item.materialOuter.lock)];
            this.assertValue(item.lock, "Неизвестный тип замка: " + item.materialOuter.lock);
            item.lock = item.lock || this.getFirstValue(state.data.locks);
        }
        if (item.materialOuter.threshold) {
            item.threshold = state.data.thresholds[state.data.thresholds.findIndex(threshold => threshold.name === item.materialOuter.threshold)];
            this.assertValue(item.threshold, "Неизвестный тип выпадающего порога: " + item.materialOuter.threshold);
            item.threshold = item.threshold || this.getFirstValue(state.data.thresholds);
        }
        if (item.materialOuter.loopTypes) {
            item.loopType = state.data.loopTypes[state.data.loopTypes.findIndex(loopType => loopType.name === item.materialOuter.loopTypes[0])];
            this.assertValue(item.loopType, "Неизвестный тип марки перель: " + item.materialOuter.loopTypes[0]);
            item.loopType = item.loopType || this.getFirstValue(state.data.loopTypes);
        }
        if (item.materialOuter.loopsCount) {
            item.loopsCount = +item.materialOuter.loopsCount;
        }
    };

    assertValue = (value, message) => {
        if (!value) {
            console.error(message);
        }
    };

    getColorWeb = (colorText) => {
        const ind = this.state.colors.findIndex(color => {
            if (color.ral) return color.ral === colorText;
            else if (color.ncs) return color.ncs === colorText;
            else return false;
        });
        return ind > -1 ? this.state.colors[ind].web : "";
    };

    setRowValueFocusLost = (rowId, prop, value) => {
        // console.log("setValueFocusLost", rowId, prop, value);
        const id = this.state.rows.findIndex(row => row.id === rowId);
        const row = this.state.rows[id];
        let item = {...row, [prop]: value};

        if (prop[0] === 'openingHeight' && value && (value < item.model.minHeight || value > item.model.maxHeight)) {
            this.setRowValue(rowId, prop, Math.max(item.openingHeight, item.model.minHeight));
        } else if (prop[0] === 'openingWidth' && value && value < item.model.minWidth) {
            this.setRowValue(rowId, prop, Math.max(item.openingWidth, item.model.minWidth));
        } else if (prop[0] === 'clearHeight' && value && value < 1) {
            this.setRowValue(rowId, prop, Math.max(item.clearHeight, 1));
        } else if (prop[0] === 'clearWidth' && value && value < 1) {
            this.setRowValue(rowId, prop, Math.max(item.clearWidth, 1));
        } else if (prop[0] === 'loopsCount' && value && value < 1) {
            this.setRowValue(rowId, prop, Math.max(item.loopsCount, 1));
        } else if (prop[0] === 'doorsCount' && (!value || value < 1)) {
            this.setRowValue(rowId, prop, Math.max(item.doorsCount, 1));
        } else if (prop[0] === 'discount' && value && value < 0) {
            this.setRowValue(rowId, prop, Math.max(item.discount, 0));
        } else if (prop[0] === 'dealerMargin' && value && value < 0) {
            this.setRowValue(rowId, prop, Math.max(item.dealerMargin, 0));
        } else if (prop[0] === 'handlePrice' && (!value || value < 0)) {
            this.setRowValue(rowId, prop, 0);
        }
    };

    onCalculate = () => {
        if (!this.isRequiredFilled(this.state.rows)) {
            return this.setState({error: true, errorMessage: this.fillError, total: "", additionalExpenses: {...this.state.additionalExpenses, installation: "", liftingTotal: "", total: ""}});
        }
        const currency = this.getValue(this.state.currency, this.state.data.currencies);
        if (currency === "EUR") {
            this.calculateAndUpdateState([], currency);
        } else {
            this.props.apiService.getCurrencies2(currencies => this.calculateAndUpdateState(currencies, currency));
        }
    };

    calculateAndUpdateState = (currencies, currency) => {
        // console.log(currencies);
        if (this.state.rows.filter(row => row.openingHeight && row.openingHeight < row.model.minHeight).length > 0) {
            return this.setState({error: true, errorMessage: (this.openingHeightError), total: "", additionalExpenses: {...this.state.additionalExpenses, installation: "", liftingTotal: "", total: ""}});
        }
        let total = (this.calculateTotal(this.state.rows, this.state.additionalExpenses.totalDiscount)).toFixed(2);
        try {
            if (currency !== 'EUR') {
                if (currencies.length === 0) {
                    throw new Error("ResponseError");
                }
                const eurIndex = currencies.findIndex(cur => cur.ccy === 'EUR');
                if (eurIndex === -1 || !currencies[eurIndex]) {
                    throw new Error("CurrencyError");
                }
                this.props.setOrderHeaderEUR(currencies[eurIndex]);
                total = (total * currencies[eurIndex].sale).toFixed(2);      // UAH
                if (currency === 'USD') {
                    const usdIndex = currencies.findIndex(cur => cur.ccy === 'USD');
                    if (usdIndex === -1 || !currencies[usdIndex]) {
                        throw new Error("CurrencyError");
                    }
                    total = (total / currencies[usdIndex].sale).toFixed(2);      // USD
                }
                if (isNaN(total)) {
                    throw new Error("CurrencyError");
                }
            }
        } catch (err) {
            console.log(err);
            if (err.message === 'ResponseError') {
                return this.setState({error: true, errorMessage: this.bankError, total: "", additionalExpenses: {...this.state.additionalExpenses, installation: "", liftingTotal: "", total: ""}});
            } else {
                return this.setState({error: true, errorMessage: this.currenciesError, total: "", additionalExpenses: {...this.state.additionalExpenses, installation: "", liftingTotal: "", total: ""}});
            }
        }
        this.setState(state => {
            return {error: false, total: total + state.data.currencies[this.getValue(state.currency, state.data.currencies)],
                additionalExpenses: this.calculateAdditionalExpenses(state.additionalExpenses, state.rows, state.data.installationPrice)
            };
        });
    };

    calculateUnitPrice = (row) => {
        const priceItem = row.model.prices.filter(priceItem => Number(priceItem.height) <= row.openingHeight).slice(-1)[0];
        const square = row.openingHeight !== '' && row.openingWidth !== '' ?  (row.openingHeight / 1000) * (row.openingWidth / 1000) : 0;
        const profileColorPrice = this.state.data.profileRalNcsPrice && row.profileColor && (row.profileColor.includes("RAL") || row.profileColor.includes("NCS")) ? (+this.state.data.profileRalNcsPrice) : 0;
        const unitPrice = (priceItem ? priceItem.price : 0) + profileColorPrice + (row.materialOuter.price * square) + (row.materialOuter.onlyMaterial ? 0 : row.materialInner.price * square)
            + (row.handlePrice ? +row.handlePrice : 0) + row.lock.price + row.locking.price + row.threshold.price + row.loopsTotal;
        const unitPriceWithMargin = row.dealerMargin ? (unitPrice / (1 - (+row.dealerMargin) / 100)) : unitPrice;
        return Number(unitPriceWithMargin.toFixed(2));
    };

    calculateSum = (row) => {
        return row.unitPrice * row.doorsCount;
    };

    calculateTotal = (rows, totalDiscount) => {
        const totalSum = rows.map(row => {
            const rowSum = row.sum ? row.sum : 0;
            return row.discount ? (rowSum - (rowSum / 100) * (+row.discount)) : rowSum;
        }).reduce((val1, val2) => val1 + val2);
        return totalDiscount ? (totalSum - (totalSum / 100) * (+totalDiscount)) : totalSum;
    };

    calculateAdditionalExpenses = (currentAdditionalExpenses, rows, installationPrice) => {
        const doorsCount = rows.map(row => row.doorsCount).reduce((val1, val2) => val1 + val2);
        const installation = doorsCount * installationPrice;
        const delivery = currentAdditionalExpenses.delivery.price ? currentAdditionalExpenses.delivery.price : (currentAdditionalExpenses.deliveryCustomPrice ? currentAdditionalExpenses.deliveryCustomPrice : 0);
        const liftingTotal = currentAdditionalExpenses.lifting.price * doorsCount * (currentAdditionalExpenses.lifting.pricePerFloor && currentAdditionalExpenses.floor ? currentAdditionalExpenses.floor : 1);
        const total = installation + delivery + liftingTotal;
        return {...currentAdditionalExpenses, installation: installation, liftingTotal: liftingTotal, total: total};
    };

    isRequiredFilled = (rows) => {
        for (const row of rows) {
            if (!row['openingHeight'] || !row['openingWidth'] || !row['clearHeight'] || !row['clearWidth'] || (!row['handlePrice'] && row['handlePrice'] === "") || !row['loopsCount'] || !row['doorsCount']) {
                return false;
            }
        }
        return true;
    };

    getValue = (val, dataArray) => val ? val : Array.isArray(dataArray) ? dataArray[0] : Object.keys(dataArray)[0];

    getFirstValue = (dataArray) => Array.isArray(dataArray) ? dataArray.find(d => !d.invisible) : Object.keys(dataArray)[0];

    setAdditionalExpensesValue = (prop, value) => {
        // console.log("setAdditionalExpensesValue", prop, value);
        this.setState(state => {
            if (prop[0] === 'delivery') {
                value = state.data.deliveries[state.data.deliveries.findIndex(delivery => delivery.place === value)];
            } else if (prop[0] === 'deliveryCustomPrice' && value) {
                value = +value;
            } else if (prop[0] === 'totalDiscount') {
                value = value ? Math.min(+value, 100) : value;
                return {additionalExpenses: {...state.additionalExpenses, [prop]: value}, total: ""};
            } else if (prop[0] === 'lifting') {
                value = state.data.liftings[state.data.liftings.findIndex(lifting => lifting.name === value)];
            } else if (prop[0] === 'floor' && value) {
                value = +value;
            }
            return {additionalExpenses: {...state.additionalExpenses, [prop]: value}};
        });
        if (this.state.total && (prop[0] === 'delivery' || prop[0] === 'deliveryCustomPrice' || prop[0] === 'lifting' || prop[0] === 'floor')) {
            this.onCalculate();
        }
    };

    setAdditionalExpensesValueFocusLost = (prop, value) => {
        // console.log("setAdditionalExpensesValue", prop, value);
        if (prop[0] === 'deliveryCustomPrice' && (!value || value < 0)) {
            this.setAdditionalExpensesValue(prop, 0);
        } else if (prop[0] === 'totalDiscount' && value && value < 0) {
            this.setAdditionalExpensesValue(prop, Math.max(value, 0));
        } else if (prop[0] === 'lifting' && (!value || value < 0)) {
            this.setAdditionalExpensesValue(prop, 0);
        } else if (prop[0] === 'floor' && (!value || value < 0)) {
            this.setAdditionalExpensesValue(prop, 0);
        }
    };

    componentDidMount() {
        this.props.apiService.getData().then(data => {
            this.setState(state => {
                return {loaded: true, data: data, additionalExpenses: {...state.additionalExpenses, delivery: this.getValue("", data.deliveries), lifting: this.getValue("", data.liftings)}}
            });
            this.onAddRow();
        }).catch(error => {
            console.error(error);
            this.setState({loadError: true});
        });
    }

    render() {
        if (this.state.loadError) {
            return (<div className="text-danger mx-3">Ошибка получения данных с сервера!</div>);
        } else if (!this.state.loaded) {
            return (<div className="mx-3">Загрузка...</div>);
        }
        return (
            <main className="container-fluid">
                <h1>Расчет стоимости дверей</h1>
                <table className="table table-bordered">
                    <tbody>
                    {this.state.rows.map((row, index) => {
                        return <DoorsTableRow key={row.id} index={index} {...row} data={this.state.data} colors={this.state.colors}
                                              fillError={this.state.error && this.state.errorMessage === this.fillError}
                                              setRowValue={(e) => this.setRowValue(row.id, [e.target.name], e.target.value)}
                                              setRowValue2={(prop, value) => {this.setRowValue(row.id, prop, value)}}
                                              setRowValueFocusLost={(e) => this.setRowValueFocusLost(row.id, [e.target.name], e.target.value)}
                                              removeRow={() => this.onRemoveRow(row.id)}/>
                    })}
                    </tbody>
                </table>
                <div className="small text-muted text-right mb-3">*Высота проема свыше 3 метров просчитывается индивидуально</div>
                {this.state.error ? <div className="alert alert-danger text-center" role="alert">{this.state.errorMessage}</div> : ""}
                <div className="mb-1 text-right">
                    Скидка % <input id="totalDiscount" name="totalDiscount" type="number" value={this.state.additionalExpenses.totalDiscount} min="0" className="form-control form-control-sm d-inline" style={{width: 100}}
                           onChange={(e) => this.setAdditionalExpensesValue([e.target.name], e.target.value)}
                           onBlur={(e) => this.setAdditionalExpensesValueFocusLost([e.target.name], e.target.value)}/>
                </div>
                <div className="row">
                    <div className="col-6 col-md pb-2 d-inline-block">
                        <button className="btn btn-danger btn-sm text-nowrap" onClick={this.onAddRow}>Добавить расчет</button>
                    </div>
                    <div className="col-6 col-md text-right text-nowrap">
                        <label htmlFor="currency" className="d-inline">Валюта </label>
                        <div className="d-inline-block">
                            <select id="currency" name="currency" value={this.state.currency} className="custom-select custom-select-sm" onChange={(e) => {this.setState({currency: e.target.value})}}>
                                {Object.keys(this.state.data.currencies).map(key => {
                                    return <option key={key} value={key}>{key}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-6 col-md-auto">
                        <button className="btn btn-dark btn-sm" onClick={this.onCalculate}>Рассчитать</button>
                    </div>
                    <div className="col-6 col-md-auto text-right">
                        <div className="d-inline-block text-nowrap">
                            <label htmlFor="total" className="d-inline">Итого: </label>
                            <input id="total" type="text" value={this.state.total} readOnly
                                   className="form-control form-control-sm bg-warning d-inline" style={{width: 100}}/>
                        </div>
                    </div>
                </div>
                <AdditionalExpenses data={this.state.data} expenses={this.state.additionalExpenses}
                                    setValue={(e) => this.setAdditionalExpensesValue([e.target.name], e.target.value)}
                                    setValueFocusLost={(e) => this.setAdditionalExpensesValueFocusLost([e.target.name], e.target.value)}/>
                <div className="row justify-content-end mt-2 mt-md-0">
                    <div className="mr-2">
                        <Print state={this.state} orderData={this.props.orderData}/>
                    </div>
                    <div className="mr-3">
                        <button className="btn btn-danger btn-sm" disabled={!this.state.total} onClick={() => {}}>Заказать</button>
                    </div>
                </div>
            </main>
        );
    }
}
