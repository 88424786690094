import React from "react";

import ColorPicker from "./ColorPicker";
import Opening1 from "../img/opening1.png"
import Opening2 from "../img/opening2.png"
import Opening3 from "../img/opening3.png"
import Opening4 from "../img/opening4.png"
import Opening5 from "../img/opening5.png"
import Opening6 from "../img/opening6.png"
import Opening7 from "../img/opening7.png"
import Opening8 from "../img/opening8.png"
import OpeningSm1 from "../img/openingSm1.png"
import OpeningSm2 from "../img/openingSm2.png"
import OpeningSm3 from "../img/openingSm3.png"
import OpeningSm4 from "../img/openingSm4.png"
import OpeningSm5 from "../img/openingSm5.png"
import OpeningSm6 from "../img/openingSm6.png"
import OpeningSm7 from "../img/openingSm7.png"
import OpeningSm8 from "../img/openingSm8.png"

const DoorsTableRow = (props) => {
    const currentOpening = props.opening === 'Тип открывание №2' ? Opening2 : props.opening === 'Тип открывание №3' ? Opening3 : props.opening === 'Тип открывание №4' ? Opening4
        : props.opening === 'Тип открывание №5' ? Opening5 : props.opening === 'Тип открывание №6' ? Opening6 : props.opening === 'Тип открывание №7' ? Opening7 : props.opening === 'Тип открывание №8' ? Opening8 : Opening1;
    const currentOpeningSm = props.opening === 'Тип открывание №2' ? OpeningSm2 : props.opening === 'Тип открывание №3' ? OpeningSm3 : props.opening === 'Тип открывание №4' ? OpeningSm4
        : props.opening === 'Тип открывание №5' ? OpeningSm5 : props.opening === 'Тип открывание №6' ? OpeningSm6 : props.opening === 'Тип открывание №7' ? OpeningSm7 : props.opening === 'Тип открывание №8' ? OpeningSm8 : OpeningSm1;
    return (
        <React.Fragment>
            <tr className="thead-dark">
                <th colSpan="8" className="">
                    <div className="d-flex justify-content-between">
                        <div>Расчет Д{props.index + 1}</div>
                        <div>
                            <button type="button" className="close" style={{color: "white"}} aria-label="Close" onClick={props.removeRow}>
                                <span aria-hidden="true" className="text-warning">&times;</span>
                            </button>
                        </div>
                    </div>
                </th>
            </tr>
            <tr className="row row-cols-xl-8 row-cols-md-4 row-cols-sm-2 row-cols-1 mx-0">
                <td className="col">
                    <div>
                        <label htmlFor="model">Модель</label>
                        <select id="model" name="model" value={props.model.name} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {props.data.models.map((model, i) => {
                                return model.invisible ? "" :
                                    <option key={i} value={model.name}>{model.name}</option>
                            })}
                        </select>
                    </div>
                    <ColorPicker suffix={props.id} id="profile-color" name="profileColor" value={props.profileColor} web={props.profileColorWeb} label="Цвет профиля" colors={props.colors} dropdownList={true} disabled={props.materialOuter.profileColor} setRowValue={props.setRowValue2}/>
                    <div>
                        <label htmlFor="door-type">Тип двери</label>
                        <select id="door-type" name="doorType" value={props.doorType.name} className="custom-select custom-select-sm" disabled={props.materialOuter.doorType} onChange={props.setRowValue}>
                            {props.data.doorTypes.map((doorType, i) => {
                                return <option key={i} value={doorType.name}>{doorType.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="opening-type">Тип проема</label>
                        <select id="opening-type" name="openingType" value={props.openingType} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            <option value="Кирпич">Кирпич</option>
                            <option value="Гипсокартон">Гипсокартон</option>
                        </select>
                    </div>
                </td>
                <td className="col">
                    <div>
                        <label htmlFor="opening-height">Высота&nbsp;проема<span className="text-danger">*</span></label>
                        <input id="opening-height" name="openingHeight" type="number" value={props.openingHeight} min={props.model.minHeight} max={props.model.maxHeight} step={props.doorType.heightStep}
                               className={`form-control form-control-sm${props.fillError && !props.openingHeight ? " is-invalid" : ""}`} disabled={props.materialOuter.openingHeight}
                               onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                        {props.maxHeightError ? <div className="text-danger my-0" style={{fontSize: "80%"}}><small>Макс. {props.model.maxHeight} для {props.model.name}</small></div> : ""}
                        {props.minHeightError ? <div className="text-danger my-0" style={{fontSize: "80%"}}><small>Мин. {props.model.minHeight} для {props.model.name}</small></div> : ""}
                    </div>
                    <div>
                        <label htmlFor="opening-width">Ширина&nbsp;проема<span className="text-danger">*</span></label>
                        <input id="opening-width" name="openingWidth" type="number" value={props.openingWidth} min={props.model.minWidth} max={props.model.maxWidth} step="100"
                               className={`form-control form-control-sm${props.fillError && !props.openingWidth ? " is-invalid" : ""}`} onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                        {props.maxWidthError ? <div className="text-danger my-0" style={{fontSize: "80%"}}><small>Макс. {props.model.maxWidth} для {props.model.name}</small></div> : ""}
                        {props.minWidthError ? <div className="text-danger my-0" style={{fontSize: "80%"}}><small>Мин. {props.model.minWidth} для {props.model.name}</small></div> : ""}
                    </div>
                    <div>
                        <label htmlFor="clear-height">Высота&nbsp;в&nbsp;свету<span className="text-danger">*</span></label>
                        <input id="clear-height" name="clearHeight" type="number" value={props.clearHeight} className={`form-control form-control-sm${props.fillError && !props.clearHeight ? " is-invalid" : ""}`} disabled onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                    </div>
                    <div>
                        <label htmlFor="clear-width">Ширина&nbsp;в&nbsp;свету<span className="text-danger">*</span></label>
                        <input id="clear-width" name="clearWidth" type="number" value={props.clearWidth} className={`form-control form-control-sm${props.fillError && !props.clearWidth ? " is-invalid" : ""}`}  disabled onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                    </div>
                </td>
                <td className="col">
                    <div>
                        <label htmlFor={`opening${props.index}`}>Открывание</label><br/>
                        <button id={`opening${props.index}`} type="button" data-toggle="modal" data-target={`#openingModal${props.index}`}
                                className="btn btn-sm btn-outline-secondary btn-warning"
                                style={{width: "100%"}}>{props.opening}
                        </button>
                        <div className="modal fade" id={`openingModal${props.index}`} tabIndex="-1" role="dialog"
                             aria-labelledby={`#openingModalLabel${props.index}`} aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id={`#openingModalLabel${props.index}`}>Открывание</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="opening-div modal-body row d-flex justify-content-center text-center">
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2(`opening`, "Тип открывание №1")}>
                                            <span>Тип открывание №1</span><br/>
                                            <div><img src={Opening1} className="mb-1" style={{width: "200px"}} alt="Тип открывание №1"/></div>
                                            <div><img src={OpeningSm1} className="mb-1" style={{width: "200px"}} alt="Тип открывание №1 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №2")}>
                                            <span>Тип открывание №2</span><br/>
                                            <div><img src={Opening2} className="mb-1" style={{width: "200px"}} alt="Тип открывание №2"/></div>
                                            <div><img src={OpeningSm2} className="mb-1" style={{width: "200px"}} alt="Тип открывание №2 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №3")}>
                                            <span>Тип открывание №3</span><br/>
                                            <div><img src={Opening3} className="mb-1" style={{width: "200px"}} alt="Тип открывание №3"/></div>
                                            <div><img src={OpeningSm3} className="mb-1" style={{width: "200px"}} alt="Тип открывание №3 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №4")}>
                                            <span>Тип открывание №4</span><br/>
                                            <div><img src={Opening4} className="mb-1" style={{width: "200px"}} alt="Тип открывание №4"/></div>
                                            <div><img src={OpeningSm4} className="mb-1" style={{width: "200px"}} alt="Тип открывание №4 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №5")}>
                                            <span>Тип открывание №5</span><br/>
                                            <div><img src={Opening5} className="mb-1" style={{width: "200px"}} alt="Тип открывание №5"/></div>
                                            <div><img src={OpeningSm5} className="mb-1" style={{width: "200px"}} alt="Тип открывание №5 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №6")}>
                                            <span>Тип открывание №6</span><br/>
                                            <div><img src={Opening6} className="mb-1" style={{width: "200px"}} alt="Тип открывание №6"/></div>
                                            <div><img src={OpeningSm6} className="mb-1" style={{width: "200px"}} alt="Тип открывание №6 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №7")}>
                                            <span>Тип открывание №7</span><br/>
                                            <div><img src={Opening7} className="mb-1" style={{width: "200px"}} alt="Тип открывание №7"/></div>
                                            <div><img src={OpeningSm7} className="mb-1" style={{width: "200px"}} alt="Тип открывание №7 Схема"/></div>
                                        </div>
                                        <div className="col-auto" data-dismiss="modal" style={{cursor: "pointer"}} onClick={() => props.setRowValue2("opening", "Тип открывание №8")}>
                                            <span>Тип открывание №8</span><br/>
                                            <div><img src={Opening8} className="mb-1" style={{width: "200px"}} alt="Тип открывание №8"/></div>
                                            <div><img src={OpeningSm8} className="mb-1" style={{width: "200px"}} alt="Тип открывание №8 Схема"/></div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Отмена</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src={currentOpening} className="mt-2" style={{width: "100%"}} alt={props.opening}/>
                        <div>
                            <label htmlFor="opening-upper-title">Название&nbsp;помещения</label>
                            <input id="opening-upper-title" name="openingUpperTitle" value={props.openingUpperTitle} className={`form-control form-control-sm`} onChange={props.setRowValue}/>
                        </div>
                        <img src={currentOpeningSm} style={{width: "100%"}} alt={props.opening}/>
                        <div>
                            <label htmlFor="opening-lower-title">Название&nbsp;помещения</label>
                            <input id="opening-lower-title" name="openingLowerTitle" value={props.openingLowerTitle} className={`form-control form-control-sm`} onChange={props.setRowValue}/>
                        </div>
                    </div>
                </td>
                <td className="col">
                    <div>
                        <label htmlFor="materialOuter">Материал (КМ)</label>
                        <select id="materialOuter" name="materialOuter" value={props.materialOuter.name} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {props.data.materials.map((material, i) => {
                                return material.invisible ? "" :
                                    <option key={i} value={material.name}>{material.name}</option>
                            })}
                        </select>
                    </div>
                    <ColorPicker suffix={props.id} id="material-outer-color" name="materialOuterColor" value={props.materialOuterColor} web={props.materialOuterColorWeb} label="Цвет (КМ)" colors={props.colors} dropdownList={false} setRowValue={props.setRowValue2}/>
                    {props.materialOuter.onlyMaterial ? "" :
                        <React.Fragment>
                            <div>
                                <label htmlFor="materialInner">Материал (КБ)</label>
                                <select id="materialInner" name="materialInner" value={props.materialInner.name}
                                        className="custom-select custom-select-sm" onChange={props.setRowValue}>
                                    {props.data.materials.map((material, i) => {
                                        return (material.invisible || material.onlyMaterial) ? "" :
                                            <option key={i} value={material.name}>{material.name}</option>
                                    })}
                                </select>
                            </div>
                            <ColorPicker suffix={props.id} id="material-inner-color" name="materialInnerColor" value={props.materialInnerColor} web={props.materialInnerColorWeb} label="Цвет (КБ)" colors={props.colors} dropdownList={false} setRowValue={props.setRowValue2}/>
                        </React.Fragment>
                    }
                </td>
                <td className="col">
                    <div>
                        <label htmlFor="lock">Замок</label>
                        <select id="lock" name="lock" value={props.lock.name} className="custom-select custom-select-sm" disabled={props.materialOuter.lock} onChange={props.setRowValue}>
                            {props.data.locks.map((lock, i) => {
                                return <option key={i} value={lock.name}>{lock.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="locking">Запирание</label>
                        <select id="locking" name="locking" value={props.locking.name} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {props.data.lockings.map((locking, i) => {
                                return <option key={i} value={locking.name}>{locking.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="threshold" className="text-nowrap">Выпадающий порог</label>
                        <select id="threshold" name="threshold" value={props.threshold.name} className="custom-select custom-select-sm" disabled={props.materialOuter.threshold} onChange={props.setRowValue}>
                            {props.data.thresholds.map((threshold, i) => {
                                return <option key={i} value={threshold.name}>{threshold.name}</option>
                            })}
                        </select>
                    </div>
                </td>
                <td className="col">
                    <div>
                        <label htmlFor="handle">Ручка</label>
                        <select id="handle" name="handle" value={props.handle.name} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {props.data.handles.map((handle, i) => {
                                return handle.invisible ? "" :
                                    <option key={i} value={handle.name}>{handle.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="handle-model">Модель</label>
                        <select id="handle-model" name="handleModel" value={props.handleModel ? props.handleModel.name : ""} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {!props.handle.models ? "" : props.handle.models.map((model, i) => {
                                return model.invisible ? "" :
                                    <option key={i} value={model.name}>{model.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="handle-color">Цвет</label>
                        <select id="handle-color" name="handleColor" value={props.handleColor ? props.handleColor.name : ""} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {props.handleModel ? props.handleModel.types.map((type, i) => {
                                return type.invisible ? "" :
                                    <option key={i} value={type.name}>{type.name}</option>
                            }) : ""}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="handle-price">Цена €<span className="text-danger">*</span></label>
                        <input id="handle-price" name="handlePrice" value={props.handlePrice} min="0" type="number" className={`form-control form-control-sm${props.fillError && !props.handlePrice && props.handlePrice === "" ? " is-invalid" : ""}`} onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                    </div>
                </td>
                <td className="col">
                    <div>
                        <label htmlFor="loop-type">Марка&nbsp;петель</label>
                        <select id="loop-type" name="loopType" value={props.loopType.name} className="custom-select custom-select-sm" onChange={props.setRowValue}>
                            {props.materialOuter.loopTypes ? props.materialOuter.loopTypes.map((loopType, i) => {
                                    return loopType.invisible ? "" :
                                        <option key={i} value={loopType}>{loopType}</option>
                                }) :
                            props.data.loopTypes.map((loopType, i) => {
                                return loopType.invisible ? "" :
                                <option key={i} value={loopType.name}>{loopType.name}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="loops-count">Кол.петель<span className="text-danger">*</span></label>
                        <input id="loops-count" name="loopsCount" type="number" value={props.loopsCount} min="1" className={`form-control form-control-sm${props.fillError && !props.loopsCount ? " is-invalid" : ""}`} disabled={props.materialOuter.loopsCount}
                               onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                    </div>
                    {/*{props.loopType.loopCloser ? <div className="my-0" style={{fontSize: "80%"}}><small>+ {props.loopType.loopCloser} петель с доводчиком</small></div> : ""}*/}
                    {!props.loopType.loopCloser ? "" :
                        <div>
                            <label htmlFor="unit-price">Петель&nbsp;с&nbsp;доводчиком</label>
                            <input id="unit-price" type="number" value={props.loopType.loopCloser}
                                   className="form-control form-control-sm" readOnly/>
                        </div>
                    }
                </td>
                <td className="col">
                    <div>
                        <label htmlFor="doors-count">Кол.дверей<span className="text-danger">*</span></label>
                        <input id="doors-count" name="doorsCount" type="number" value={props.doorsCount} min="1" className={`form-control form-control-sm${props.fillError && !props.doorsCount ? " is-invalid" : ""}`} onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                        <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                    </div>
                    <div>
                        <label htmlFor="unit-price">Цена шт. €</label>
                        <input id="unit-price" type="number" value={props.unitPrice.toFixed(2)} className="form-control form-control-sm" readOnly/>
                    </div>
                    <div>
                        <label htmlFor="discount">Дилерская&nbsp;наценка&nbsp;%</label>
                        <input id="dealerMargin" name="dealerMargin" type="number" value={props.dealerMargin} min="0" className="form-control form-control-sm" onChange={props.setRowValue} onBlur={props.setRowValueFocusLost}/>
                    </div>
                    <div>
                        <label htmlFor="sum">Сумма €</label>
                        <input id="sum" type="number" value={props.sum.toFixed(2)} className="form-control form-control-sm" readOnly/>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default DoorsTableRow;
