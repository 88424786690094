import React, {Component} from "react";
// import ErrorIndicator from "./ErrorIndicator/ErrorIndicator";

export default class ErrorBoundry extends Component {
    state = {
        hasError: false
    };

    componentDidCatch(error, errorInfo) {
        this.setState({hasError: true});
    }

    render() {
        if (this.state.hasError) {
            // return <ErrorIndicator/>
            return <div>Ошибка</div>
        }
        return this.props.children;
    }
}