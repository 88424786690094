import React from "react";

import Logo from "../img/logo.png"

const Footer = () => {
    return (
        <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-3 mx-0 mt-5 bg-dark text-light">
            <div className="col pt-3">
                <img src={Logo} alt="logo" className="ml-4 my-2"/>
            </div>
            <div className="col pt-3">
                <ul className="list-unstyled ml-4">
                    <li><i className="fa fa-marker"></i>г. Днепр.</li>
                    <li><i className="fa fa-marker"></i>ул. Святослава Храброго 35 (2 этаж),</li>
                    <li><i className="fa fa-marker"></i>ТЦ «Дом и Интерьер»</li>
                    <li><a href="tel:+38 (067) 14 69 971" target="" className="text-light"><i
                        className="fa fa-phone"></i>+38 (067) 14 69 971</a> (Киев)
                    </li>
                    <li><a href="tel:+38 (067) 636 52 80" target="" className="text-light"><i
                        className="fa fa-phone"></i>+38 (067) 636 52 80</a> (Днепр)
                    </li>
                    <li><a href="mailto:allumsystems@gmail.com" target="" className="text-light"><i
                        className="fa fa-envelope"></i> allumsystems@gmail.com</a></li>
                    <li className="mt-3 mb-5">
                        <a href="https://www.facebook.com/Allumitdoorskiev/" target=" _blank"
                           className="text-warning pr-3"><i className="fa fa-facebook-official"
                                                            style={{fontSize: 30}}></i></a>
                        <a href="https://www.instagram.com/allumit_doors_kyiv/?hl=ru" target=" _blank"
                           className="text-warning"><i className="fa fa-instagram" style={{fontSize: 30}}></i></a>
                    </li>
                </ul>
            </div>
            <div className="col col-sm-12 pt-3 text-right">
                <div className="ml-4 my-2 mr-3">
                    <iframe height="300" className="w-100" style={{border:0}} allowFullScreen="" aria-hidden="false"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.6717841785776!2d35.03239741540237!3d48.462826679250675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe2e17ec57beb%3A0xbaa55102c7f7dfde!2z0YPQuy4g0KHQstGP0YLQvtGB0LvQsNCy0LAg0KXRgNCw0LHRgNC-0LPQviwgMzUsINCU0L3QuNC_0YDQviwg0JTQvdC10L_RgNC-0L_QtdGC0YDQvtCy0YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDQ5MDAw!5e0!3m2!1sru!2sua!4v1586723061872!5m2!1sru!2sua"
                        tabIndex="0" title="г. Днепр. ул. Святослава Храброго 35">
                    </iframe>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
