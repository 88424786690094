import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, Redirect, NavLink} from 'react-router-dom'

import ErrorBoundry from "./ErrorBoundry";
import DoorsCalculator from "./DoorsCalculator";
import Header from "./Header";
import Footer from "./Footer";
import SlidingCalculator from "./SlidingCalculator";
import OrderHeader from "./OrderHeader";
import ApiService from "../ApiService";

export default class App extends Component {
    state = {
        orderData: {
            isManager: false, orderNum: "", manager: "", email: "", tel: "", date: new Date(), rate: "",
            city: "", address: "", receiver: "", receiverTel: "", receiverEmail: ""
        }
    };
    apiService = new ApiService();

    setOrderValue = (prop, value) => {
        // console.log("setOrderValue", prop, value);
        if (prop[0] === 'date') {
            value = value ? new Date(value) : "";
        } else if (['manager', 'email', 'tel', 'city', 'address'].includes(prop[0])) {
            window.localStorage[prop[0]] = value;
        }
        this.setState(state => {
            return {orderData: {...state.orderData, [prop]: value}};
        })
    };

    setOrderHeaderEUR = (eurCurrencies) => {
        this.setState(state => {
            return {orderData: {...state.orderData, rate: Number(eurCurrencies.sale).toFixed(2)}}
        });
    };

    setIsManager = (isManager) => {
        this.setState(state => {
            return {orderData: {...state.orderData, isManager: isManager}};
        });
    };

    componentDidMount() {
        this.apiService.getCurrencies2(currencies => {
            try {
                this.setState(state => {
                    return {orderData: {...state.orderData, manager: (window.localStorage.manager ? window.localStorage.manager : ""),
                            email: (window.localStorage.email ? window.localStorage.email : ""), tel: (window.localStorage.tel ? window.localStorage.tel : ""),
                            city: (window.localStorage.city ? window.localStorage.city : ""), address: (window.localStorage.address ? window.localStorage.address : "")}}
                });
            } catch (e) {
                console.error(e);
            }
            try {
                const eurIndex = currencies.findIndex(cur => cur.ccy === 'EUR');
                if (eurIndex > -1 && currencies[eurIndex]) {
                    this.setOrderHeaderEUR(currencies[eurIndex]);
                } else {
                    console.error("Received wrong data from PrivatBank: ", currencies);
                }
            } catch (e) {}
        });
    }

    render() {
        return (
            <ErrorBoundry>
                <BrowserRouter>
                    <Header/>
                    <OrderHeader setOrderValue={(e) => this.setOrderValue([e.target.name], e.target.value)} data={this.state.orderData} setIsManager={this.setIsManager}/>
                    <nav className="pt-3 bg-light">
                        <ul className="nav justify-content-center nav-tabs">
                            <li className="nav-item">
                                <NavLink exact to="/" className="nav-link" style={{color: "#343a40"}} activeClassName="text-warning font-weight-bold active">Двери</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/sliding" className="nav-link" style={{color: "#343a40"}} activeClassName="text-warning font-weight-bold active" activeStyle={{color: "#ffc107"}}>Раздвижные системы</NavLink>
                            </li>
                        </ul>
                    </nav>
                    <Switch>
                        <Route exact path="/" render={() => <DoorsCalculator orderData={this.state.orderData} apiService={this.apiService} setOrderHeaderEUR={this.setOrderHeaderEUR}/>}/>
                        <Route exact path="/sliding" render={() => <SlidingCalculator orderData={this.state.orderData} apiService={this.apiService}/>}/>
                        <Redirect to="/"/>
                    </Switch>
                    <Footer/>
                </BrowserRouter>
            </ErrorBoundry>
        );
    }
}
