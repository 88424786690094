import React, {Component} from "react";

import "./ColorPickerSearch.css"

export default class ColorPickerSearch extends Component {
    state = {
        text: "",
        colors: [],
        itemsCount: 0,
        pagesCount: 0,
        page: 0
    };
    itemsOnPage = 10;

    componentDidUpdate(prevProps, prevState, snapshot) {
        // document.getElementById("text-search").focus();
    }

    componentDidMount() {
        this.makeSearch("");
    }
    onChange = (e) => this.makeSearch(e.target.value);

    makeSearch = (text) => {
        const newColors = this.props.colors.filter(color => {
            const ralMatch = color.ral && color.ral.includes(text);
            const ncsMatch = color.ncs && color.ncs.includes(text);
            return ralMatch || ncsMatch;
        });
        const newPagesCount = Math.floor(newColors.length / this.itemsOnPage) + (newColors.length % this.itemsOnPage > 0 ? 1 : 0);
        this.setState({text: text, colors: newColors, itemsCount: newColors.length, pagesCount: newPagesCount, page: 0})
    };

    getTitle(color) {
        if (color.ral) return color.ral;
        else if (color.ncs) return color.ncs;
        else return "";
    }

    incrementPage = (pages) => {
        this.setState(state => {
            return {page: state.page + pages};
        })
    };

    render() {
        const first = this.state.page * this.itemsOnPage;
        const last = Math.min(first + this.itemsOnPage, this.state.itemsCount);
        const colors = this.state.colors.slice(first, last);
        return (
            <div>
                <div className="mb-2 text-center">
                    <div className="btn-group">
                        <input id={`text-search-${this.props.suffix}`} type="search" placeholder="RAL / NCS" onChange={this.onChange} autoFocus={true}/>
                        <span id={`searchclear-${this.props.suffix}`} className="glyphicon glyphicon-remove-circle"></span>
                    </div><br/>
                    <label htmlFor={`text-search-${this.props.suffix}`}><small>Введите полное название цвета или его часть</small></label>
                </div>
                <div className="mx-3">
                    {colors.map((color, index) => {
                        return <div key={index} data-dismiss="modal" className="color-div row mb-0" style={{cursor: "pointer"}} onClick={() => this.props.selectColor(this.getTitle(color))}>
                            <div className="col border" style={{backgroundColor: color.web}}></div>
                            <div className="col border-bottom">{this.getTitle(color)}</div>
                        </div>
                    })}
                    <div className="d-flex justify-content-between mt-1">
                        <div><small className="text-muted">Найдено совпадений: {this.state.colors.length}</small></div>
                        <ul className="pagination pagination-sm">
                            <li className="page-item">
                                <button type="button" className="page-link" aria-label="Previous" disabled={this.state.page === 0} onClick={() => this.incrementPage(-1)}>
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Предыдущая</span>
                                </button>
                            </li>
                            <div className="d-inline-block px-1 text-muted" style={{paddingTop: 3}}>{first + 1}..{last}</div>
                            <li className="page-item">
                                <button type="button" className="page-link" aria-label="Next" disabled={this.state.page === this.state.pagesCount - 1} onClick={() => this.incrementPage(1)}>
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Следующая</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}