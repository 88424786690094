import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    // PDFViewer,
    PDFDownloadLink,
    Font,
    Image
} from "@react-pdf/renderer";
import Opening1 from "../img/opening1.png";
import Opening2 from "../img/opening2.png";
import Opening3 from "../img/opening3.png";
import Opening4 from "../img/opening4.png";
import Opening5 from "../img/opening5.png";
import Opening6 from "../img/opening6.png";
import Opening7 from "../img/opening7.png";
import Opening8 from "../img/opening8.png";
import OpeningSm2 from "../img/openingSm2.png";
import OpeningSm3 from "../img/openingSm3.png";
import OpeningSm4 from "../img/openingSm4.png";
import OpeningSm5 from "../img/openingSm5.png";
import OpeningSm6 from "../img/openingSm6.png";
import OpeningSm7 from "../img/openingSm7.png";
import OpeningSm8 from "../img/openingSm8.png";
import OpeningSm1 from "../img/openingSm1.png";

Font.register({family: "Roboto", src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        fontSize: 8,
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableItem: {
        textAlign: "center",
        paddingBottom: 5,

        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
    },
    itemTitle: {
        fontWeight: "bold",
        backgroundColor: "#ccc",
        padding: "1px"
    },
    canv: {
        width: "50px",
        height: "20px"
    },
    dataCol: {
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    dataHeader: {
        backgroundColor: "#ccc",
        padding: 2,
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    openingTitle: {
        borderWidth: 1,
        marginLeft: "10px",
        width: "100px"
    }
});

const MyPdfDocument = ({state, orderData}) => {
    const rows = state.rows;
    const doorsCount = (rows && rows.length > 0) ? rows.map(row => (row.doorsCount ? row.doorsCount : 0)).reduce((val1, val2) => val1 + val2) : 0;
    const deliveryExpenses = state.additionalExpenses ? (state.additionalExpenses.delivery.price ? state.additionalExpenses.delivery.price : state.additionalExpenses.deliveryCustomPrice) : "";
    return (
    <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
            <View style={{...styles.table, margin: "10px", width: "810px"}}>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataHeader, width: "40px"}}>Заказ №</Text>
                    <Text style={{...styles.dataHeader, width: "80px"}}>Менеджер</Text>
                    <Text style={{...styles.dataHeader, width: "90px"}}>e-mail</Text>
                    <Text style={{...styles.dataHeader, width: "80px"}}>Тел.</Text>
                    <Text style={{...styles.dataHeader, width: "70px"}}>Город</Text>
                    <Text style={{...styles.dataHeader, width: "100px"}}>Адрес</Text>
                    <Text style={{...styles.dataHeader, width: "80px"}}>Заказчик</Text>
                    <Text style={{...styles.dataHeader, width: "80px"}}>Тел. заказчика</Text>
                    <Text style={{...styles.dataHeader, width: "90px"}}>e-mail заказчика</Text>
                    <Text style={{...styles.dataHeader, width: "50px"}}>Дата</Text>
                    <Text style={{...styles.dataHeader, width: "50px"}}>Курс, EUR</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataCol, width: "40px"}}>{orderData.orderNum}</Text>
                    <Text style={{...styles.dataCol, width: "80px"}}>{orderData.manager}</Text>
                    <Text style={{...styles.dataCol, width: "90px"}}>{orderData.email}</Text>
                    <Text style={{...styles.dataCol, width: "80px"}}>{orderData.tel}</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{orderData.city}</Text>
                    <Text style={{...styles.dataCol, width: "100px"}}>{orderData.address}</Text>
                    <Text style={{...styles.dataCol, width: "80px"}}>{orderData.receiver}</Text>
                    <Text style={{...styles.dataCol, width: "80px"}}>{orderData.receiverTel}</Text>
                    <Text style={{...styles.dataCol, width: "90px"}}>{orderData.receiverEmail}</Text>
                    <Text style={{...styles.dataCol, width: "50px"}}>{orderData.date.toLocaleDateString()}</Text>
                    <Text style={{...styles.dataCol, width: "50px"}}>{orderData.rate}</Text>
                </View>
            </View>
            <View style={{...styles.table, borderLeftWidth:0, marginLeft: "10px", marginTop: 0, width: "810px"}}>
                {rows ? rows.map((row, index) => {
                    return (
                        <React.Fragment key={"F" + index}>
                            <View key={"T" + index} style={{...styles.tableRow, marginTop: (index > 0 && index % 3 === 0 ? "50px" : 0)}}>
                                <Text style={{...styles.tableItem, borderLeftWidth: 1, borderRightWidth: 1, paddingBottom: 0, paddingTop: 3, backgroundColor: "#999", width: "810px", height: "15px"}}>Расчет Д{index + 1}</Text>
                            </View>
                            <View key={index} style={{...styles.tableRow}}>
                                <View style={{...styles.tableCol, borderLeftWidth: 1, width: "100px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Модель</Text>
                                        <Text>{row.model.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Цвет профиля</Text>
                                        <Text>{row.profileColor} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Тип двери</Text>
                                        <Text>{row.doorType.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Тип проема</Text>
                                        <Text>{row.openingType} </Text>
                                    </View>
                                </View>
                                <View style={{...styles.tableCol, width: "90px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Высота проема</Text>
                                        <Text>{row.openingHeight} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Ширина проема</Text>
                                        <Text>{row.openingWidth} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Высота в свету</Text>
                                        <Text>{row.clearHeight} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Ширина в свету</Text>
                                        <Text>{row.clearWidth} </Text>
                                    </View>
                                </View>
                                <View style={{...styles.tableCol, width: "120px"}}>
                                    <View style={{...styles.tableItem, borderBottomWidth: 0}}>
                                        <Text style={styles.itemTitle}>Открывание</Text>
                                        {/*<Canvas style={{...styles.canv, width: "120px"}} paint={(p) => {p.rect(10, 3, 10, 10).rect(50, 3, 10, 10).moveTo(50, 13).lineTo(20, 18).stroke('blue')}}/>*/}
                                        <Image src={row.opening === 'Тип открывание №2' ? Opening2 : row.opening === 'Тип открывание №3' ? Opening3 : row.opening === 'Тип открывание №4' ? Opening4
                                            : row.opening === 'Тип открывание №5' ? Opening5 : row.opening === 'Тип открывание №6' ? Opening6 : row.opening === 'Тип открывание №7' ? Opening7 : row.opening === 'Тип открывание №8' ? Opening8 : Opening1}
                                               style={{width: "116px", marginTop: "1px", marginBottom: "0"}}/>
                                        <Text style={styles.openingTitle}>{row.openingUpperTitle} </Text>
                                        <Image src={row.opening === 'Тип открывание №2' ? OpeningSm2 : row.opening === 'Тип открывание №3' ? OpeningSm3 : row.opening === 'Тип открывание №4' ? OpeningSm4
                                            : row.opening === 'Тип открывание №5' ? OpeningSm5 : row.opening === 'Тип открывание №6' ? OpeningSm6 : row.opening === 'Тип открывание №7' ? OpeningSm7 : row.opening === 'Тип открывание №8' ? OpeningSm8 : OpeningSm1}
                                               style={{width: "118px", marginTop: "0", marginBottom: "0"}}/>
                                        <Text style={styles.openingTitle}>{row.openingLowerTitle} </Text>
                                    </View>
                                </View>
                                <View style={{...styles.tableCol, width: "100px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Материал (КМ) </Text>
                                        <Text>{row.materialOuter.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Цвет (КМ) </Text>
                                        <Text>{row.materialOuterColor} </Text>
                                    </View>
                                    {row.materialOuter.onlyMaterial ? <Text> </Text> :
                                        <View style={styles.tableItem}>
                                            <Text style={styles.itemTitle}>Материал (КБ) </Text>
                                            <Text>{row.materialInner.name} </Text>
                                        </View>
                                    }
                                    {row.materialOuter.onlyMaterial ? <Text> </Text> :
                                        <View style={styles.tableItem}>
                                            <Text style={styles.itemTitle}>Цвет (КБ) </Text>
                                            <Text>{row.materialInnerColor} </Text>
                                        </View>
                                    }
                                </View>
                                <View style={{...styles.tableCol, width: "100px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Замок</Text>
                                        <Text>{row.lock.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Запирание</Text>
                                        <Text>{row.locking.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Выпадающий порог</Text>
                                        <Text>{row.threshold.name} </Text>
                                    </View>
                                </View>
                                <View style={{...styles.tableCol, width: "100px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Ручка</Text>
                                        <Text>{row.handle.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Модель</Text>
                                        <Text>{row.handleModel ? row.handleModel.name : ""} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Цвет</Text>
                                        <Text>{row.handleColor ? row.handleColor.name : ""} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Цена €</Text>
                                        <Text>{row.handlePrice} </Text>
                                    </View>
                                </View>
                                <View style={{...styles.tableCol, width: "100px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Марка петель</Text>
                                        <Text>{row.loopType.name} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Кол.петель</Text>
                                        <Text>{row.loopsCount} </Text>
                                    </View>
                                    {!row.loopType.loopCloser ? <Text> </Text> :
                                        <View style={styles.tableItem}>
                                            <Text style={styles.itemTitle}>Петель с доводчиком</Text>
                                            <Text>{row.loopType.loopCloser} </Text>
                                        </View>
                                    }
                                </View>
                                <View style={{...styles.tableCol, width: "100px"}}>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Кол.дверей</Text>
                                        <Text>{row.doorsCount} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Цена шт. €</Text>
                                        <Text>{row.unitPrice.toFixed(2)} </Text>
                                    </View>
                                    <View style={styles.tableItem}>
                                        <Text style={styles.itemTitle}>Сумма €</Text>
                                        <Text>{row.sum.toFixed(2)} </Text>
                                    </View>
                                </View>
                            </View>
                        </React.Fragment>
                    );
                }) : ""}
            </View>

            <View style={{...styles.table, borderTopWidth: 0, marginLeft: "640px", marginTop: (rows.length % 3 === 0 ? "20px" : "0"), width: "180px"}}>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataHeader, width: "110px"}}>Всего изделий</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{doorsCount}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataHeader, width: "110px"}}>Скидка %</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.additionalExpenses.totalDiscount ? state.additionalExpenses.totalDiscount : ""}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataHeader, width: "110px"}}>Итого со скидкой</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.total}</Text>
                </View>
            </View>

            <View style={{...styles.table, marginLeft: "510px", marginTop: "10px", width: "310px"}}>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataHeader, width: "120px"}}>Работы по установке</Text>
                    <Text style={{...styles.dataHeader, width: "50px"}}>Кол-во</Text>
                    <Text style={{...styles.dataHeader, width: "70px"}}>Цена, грн.</Text>
                    <Text style={{...styles.dataHeader, width: "70px"}}>Стоимость, грн.</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataCol, width: "120px"}}>Монтажные работы</Text>
                    <Text style={{...styles.dataCol, width: "50px"}}>{doorsCount}</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.data.installationPrice}</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.additionalExpenses ? state.additionalExpenses.installation : ""}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataCol, width: "120px"}}>Доставка</Text>
                    <Text style={{...styles.dataCol, width: "50px"}}>1</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{deliveryExpenses}</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{deliveryExpenses}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataCol, width: "120px"}}>Подъем/Занос</Text>
                    <Text style={{...styles.dataCol, width: "50px"}}>1</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.additionalExpenses ? state.additionalExpenses.liftingTotal : ""}</Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.additionalExpenses ? state.additionalExpenses.liftingTotal : ""}</Text>
                </View>
                <View style={styles.tableRow}>
                    <Text style={{...styles.dataCol, width: "120px"}}>Итого за работы</Text>
                    <Text style={{...styles.dataCol, width: "50px"}}> </Text>
                    <Text style={{...styles.dataCol, width: "70px"}}> </Text>
                    <Text style={{...styles.dataCol, width: "70px"}}>{state.additionalExpenses ? state.additionalExpenses.total : ""}</Text>
                </View>
            </View>
            <View style={{textAlign: "right", marginRight: "25px", marginTop: "10px"}}>
                <Text style={{textDecoration: "underline"}}>{state.additionalExpenses.notes ? "Примечание к заказу" : ""}</Text>
                <Text>{state.additionalExpenses.notes ? state.additionalExpenses.notes : ""}</Text>
            </View>
        </Page>
    </Document>
    );
};

const Print = ({state, orderData}) => {
    return (
        <React.Fragment>
            {/*<PDFViewer style={{width: "1400px", height: "900px"}}>*/}
            {/*    <MyPdfDocument state={state} orderData={orderData}/>*/}
            {/*</PDFViewer>*/}
            {!state.total ?
                <button className="btn btn-dark btn-sm" disabled={true} onClick={() => {}}>Распечатать</button> :
                <PDFDownloadLink className="btn btn-dark btn-sm"
                                 document={<MyPdfDocument state={state}
                                                          orderData={orderData}/>}
                                 fileName={`Бланк заказа №${orderData.orderNum}.pdf`}>
                    {({loading}) => (loading ? 'Распечатать' : 'Распечатать')}
                </PDFDownloadLink>
            }
        </React.Fragment>
    );
};

export default Print;