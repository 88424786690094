import React from "react";

const OrderHeader = ({setOrderValue, data, setIsManager}) => {
    return (
        <div>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    {!data.isManager ?
                        <div className="nav-link bg-light text-warning font-weight-bold border">Для клиента</div> :
                        <button className="btn border-bottom nav-link" onClick={() => setIsManager(false)}>Для клиента</button>
                    }
                </li>
                <li className="nav-item">
                    {data.isManager ?
                        <div className="nav-link bg-light text-warning font-weight-bold border">Менеджер</div> :
                        <button className="btn border-bottom nav-link" onClick={() => setIsManager(true)}>Менеджер</button>
                    }
                </li>
            </ul>
            <div className="order-header row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-xl-6 pt-4 pb-3  mx-0 bg-light">
                {data.isManager ? <ManagerHeader setOrderValue={setOrderValue} data={data}/> : ""}
                <div className="col">
                    <label htmlFor="city">Город<span className="text-danger">*</span></label>
                    <input id="city" name="city" type="text" value={data.city} className="form-control form-control-sm" onChange={setOrderValue}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="col">
                    <label htmlFor="address">Адрес<span className="text-danger">*</span></label>
                    <input id="address" name="address" value={data.address} type="text" className="form-control form-control-sm" onChange={setOrderValue}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="col">
                    <label htmlFor="receiver">Заказчик<span className="text-danger">*</span></label>
                    <input id="receiver" name="receiver" type="text" value={data.receiver} className="form-control form-control-sm" onChange={setOrderValue}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="col">
                    <label htmlFor="receiver-tel">Тел.&nbsp;заказчика<span className="text-danger">*</span></label>
                    <input id="receiver-tel" name="receiverTel" type="tel" value={data.receiverTel} className="form-control form-control-sm" onChange={setOrderValue}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="col">
                    <label htmlFor="receiver-email">e-mail&nbsp;заказчика<span className="text-danger">*</span></label>
                    <input id="receiver-email" name="receiverEmail" type="email" value={data.receiverEmail} className="form-control form-control-sm" onChange={setOrderValue}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="col">
                    <label htmlFor="date">Дата<span className="text-danger">*</span></label>
                    <input id="date" name="date" type="date" value={data.date ? data.date.toISOString().substring(0, 10) : ""} disabled className="form-control form-control-sm" onChange={setOrderValue}/>
                    <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
                </div>
                <div className="col">
                    <label htmlFor="rate">Курс, EUR</label>
                    <input id="rate" name="rate" type="number" value={data.rate} className="form-control form-control-sm" readOnly onChange={setOrderValue}/>
                </div>
            </div>
        </div>
    );
};

const ManagerHeader = ({setOrderValue, data}) => {
    return (
        <React.Fragment>
            <div className="col">
                <label htmlFor="order-num">Заказ&nbsp;№<span className="text-danger">*</span></label>
                <input id="order-num" name="orderNum" value={data.orderNum} type="text" className="form-control form-control-sm" onChange={setOrderValue}/>
                <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
            </div>
            <div className="col">
                <label htmlFor="manager">Менеджер<span className="text-danger">*</span></label>
                <input id="manager" name="manager" value={data.manager} type="text" className="form-control form-control-sm" onChange={setOrderValue}/>
                <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
            </div>
            <div className="col">
                <label htmlFor="email">e-mail</label>
                <input id="email" name="email" type="email" value={data.email} className="form-control form-control-sm" onChange={setOrderValue}/>
            </div>
            <div className="col">
                <label htmlFor="tel">Тел.<span className="text-danger">*</span></label>
                <input id="tel" name="tel" type="tel" value={data.tel} className="form-control form-control-sm" onChange={setOrderValue}/>
                <div className="invalid-feedback my-0"><small>Обязательное поле</small></div>
            </div>
        </React.Fragment>
    );
};

export default OrderHeader;
