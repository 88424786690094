import React from "react";

import {moneyString} from "./Utils"

const AdditionalExpenses = ({data, expenses, setValue, setValueFocusLost}) => {
    return (
        <div className="my-2">
            <h5 className="text-right">Работы по установке:</h5>
            <div className="text-right">
                Монтажные работы: <input id="installation" name="installation" value={moneyString(expenses.installation, "грн")} type="text" className="form-control form-control-sm d-inline" style={{width: 100}} readOnly/>
            </div>
            <div className="mt-1 text-right">
                <label htmlFor="delivery" className="d-inline">Доставка </label>
                <div className="d-inline-block">
                    <select id="delivery" name="delivery" value={expenses.delivery.place} className="custom-select custom-select-sm" onChange={setValue}>
                        {data.deliveries.map((delivery, i) => {
                            return delivery.invisible ? "" :
                                <option key={i} value={delivery.place}>{delivery.place}</option>
                        })}
                    </select>
                </div>
                {!expenses.delivery.price ? <div className="d-inline-block"> <input id="deliveryCustomPrice" name="deliveryCustomPrice" value={expenses.deliveryCustomPrice} min="0"
                                                                                    type="number" className="form-control form-control-sm d-inline ml-1" style={{width: 80, paddingTop: "1px"}} onChange={setValue} onBlur={setValueFocusLost}/></div> : ""}
                {expenses.delivery.price ? <span className="small"> ({expenses.delivery.price}грн)</span> : <span> грн</span>}
            </div>
            {expenses.delivery && !expenses.delivery.price ? <div className="small text-danger text-right">Доставка рассчитывается индивидуально</div> : ""}
            <div className="mt-1 text-right">
                <label htmlFor="lifting" className="d-inline">Подъем/Занос </label>
                <div className="d-inline-block">
                    <select id="lifting" name="lifting" value={expenses.lifting.name} className="custom-select custom-select-sm" onChange={setValue}>
                        {data.liftings.map((lifting, i) => {
                            return lifting.invisible ? "" :
                                <option key={i} value={lifting.name}>{lifting.name}</option>
                        })}
                    </select>
                </div>
                {expenses.lifting.pricePerFloor ? <div className="d-inline-block"> <input id="floor" name="floor" value={expenses.floor} min="0"
                                                                                          type="number" className="form-control form-control-sm d-inline ml-1" style={{width: 50, paddingTop: "1px"}} onChange={setValue} onBlur={setValueFocusLost}/> этаж</div> : ""}
                <span className="small"> ({expenses.liftingTotal}грн)</span>
            </div>
            <div className="mt-1 text-nowrap text-right">
                <label htmlFor="totalExprenses" className="d-inline">Итого: </label>
                <input id="totalExprenses" type="text" value={moneyString(expenses.total, "грн")} readOnly
                       className="form-control form-control-sm bg-warning d-inline" style={{width: 100}}/>
            </div>
            <div className="mt-1 text-nowrap text-right">
                <label htmlFor="notes" className="d-inline">Примечание к заказу: <br/></label>
                <textarea id="notes" name="notes" rows="2" value={expenses.notes} className="form-control form-control-sm d-inline" style={{width: 250}} onChange={setValue}/>
            </div>
        </div>
    );
};

export default AdditionalExpenses;
