
export default class ApiService {

    async getResource (url) {
        const resp = await fetch(url);
        // console.log(resp);
        if (!resp.ok) throw new Error(`Error ${resp.status} for ${url}`);
        return await resp.json();
    };

    getData() {
        return this.getResource("doors.json").then(json => {
            return json;
        });
    }

    getCurrencies() {
        return this.getResource("https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5").then(json => {
            return json.map(item => {
                return {ccy: item.ccy, base: item.base_ccy, buy: item.buy, sale: item.sale};
            });
        });
    }

    getCurrencies2(handler) {
        this.getResource("https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5")
            .then(json => {
                handler(json.map(item => {
                    return {ccy: item.ccy, base: item.base_ccy, buy: item.buy, sale: item.sale};
                }));
            })
            .catch(error => {
                console.error(error);
                handler([]);
            });
    }
}