import React from "react";

const SlidingCalculator = () => {
    return (
        <main className="container-fluid">
            <h1>Расчет стоимости раздвижных систем</h1>
            <div>Калькулятор</div>
        </main>
    );
};

export default SlidingCalculator;
